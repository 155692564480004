<template>
  <div>
    <el-form :inline="true" :model="filter" size="mini">
      <el-form-item label="开始日期">
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="filter.begin"
          type="date"
          placeholder="选择日期"
          style="width: 150px"
          :clearable="false"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="结束日期">
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="filter.end"
          type="date"
          placeholder="选择日期"
          style="width: 150px"
          :clearable="false"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="项目">
        <project-select v-model="filter.projectId"></project-select>
      </el-form-item>
      <el-form-item label="费用">
        <type-cascader
          v-model="filter.typeId"
          info="ExpenseType"
        ></type-cascader>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getData" icon="el-icon-refresh"
          >刷新</el-button
        >
        <el-button type="success" @click="excel">
          <span class="iconfont iconexcel" style="font-size: 12px"></span>
          导出
        </el-button>
      </el-form-item>
    </el-form>
    <pl-table
      :data="data"
      border
      stripe
      :height="tableHeight"
      :row-height="rowHeight"
      use-virtual
      ref="table"
      @row-dblclick="dblclickTable"
      :summary-method="getSummaries"
      show-summary
    >
      <el-table-column
        label="项目"
        prop="projectName"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="类别"
        prop="pName"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="名称"
        prop="typeName"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="期初"
        prop="qcAmount"
        :width="$g.tableAmountWidth"
      >
        <template slot-scope="scope">{{
          scope.row.qcAmount | thousands(2)
        }}</template>
      </el-table-column>
      <el-table-column
        label="本期"
        prop="currentAmount"
        :width="$g.tableAmountWidth"
      >
        <template slot-scope="scope">{{
          scope.row.currentAmount | thousands(2)
        }}</template>
      </el-table-column>
      <el-table-column
        label="期末"
        prop="endAmount"
        :width="$g.tableAmountWidth"
      >
        <template slot-scope="scope">{{
          scope.row.endAmount | thousands(2)
        }}</template>
      </el-table-column>
    </pl-table>
  </div>
</template>

<script>
import ProjectSelect from "@/components/ProjectSelect.vue";
import TypeCascader from "@/components/TypeCascader";
import tableHeight from "@/common/tableHeightMixins";
import toExcel from "@/common/toExcel";
export default {
  mixins: [tableHeight],
  components: {
    ProjectSelect,
    TypeCascader,
  },
  data() {
    return {
      filter: {
        begin: this.$moment().subtract(1, "months").format("YYYY-MM-DD"),
        end: this.$moment().format("YYYY-MM-DD"),
      },
      data: [],
    };
  },
  activated() {
    this.getData();
  },
  methods: {
    getData() {
      let params = { ...this.filter };
      let loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$get("ProjectExpense/ExpenseSummary", params)
        .then((r) => {
          r.forEach((item) => {
            item.endAmount = this.amountFormat(
              item.qcAmount + item.currentAmount
            );
          });
          this.data = r;
        })
        .finally(() => {
          loading.close();
        });
    },
    getSummaries({ columns, data }) {
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          let total = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = this.thousandsFormat(this.amountFormat(total));
        } else {
          sums[index] = "-";
        }
      });

      return [sums];
    },
    dblclickTable(row) {
      let filter = { ...this.filter };
      filter.typeId = row.expenseTypeId;
      filter.projectId = row.projectId;
      this.$store.commit("setFilterCache", {
        name: "ExpenseDetailList",
        filter: filter,
      });
      this.$router.push({
        name: "ExpenseDetailList",
      });
    },
    excel() {
      let list = [];
      this.data.forEach((item) => {
        list.push({ ...item });
      });

      let header = ["项目", "类别", "名称", "期初", "本期", "期末"];
      let column = [
        "projectName",
        "pName",
        "typeName",
        "qcAmount",
        "currentAmount",
        "endAmount",
      ];

      toExcel(header, column, list, "项目费用汇总");
    },
  },
};
</script>

<style>
</style>